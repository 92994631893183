import { createCMSSiteTestingJob } from '../../../utils/api';
import Swal from 'sweetalert2';

interface ICreateJobPayload {
  environment: string;
  drillDown: boolean;
  sites: number[];
}

const createJob = async (env: string) => {
  const payload: ICreateJobPayload = {
    environment: env,
    drillDown: false,
    sites: [],
  };
  var cancel = false;

  const { value: isDrillDown } = await Swal.fire({
    title: 'Drill Down (Step 2 of 3)',
    input: 'select',
    inputOptions: {
      true: 'Yes',
      false: 'No',
    },
    inputPlaceholder: 'Is this a drill down?',
    showCancelButton: true,
  }).then(result => {
    if (result.isDismissed) {
      cancel = true;
    }
    return result;
  });

  if (cancel) return;

  if (isDrillDown && isDrillDown === 'true') {
    payload.drillDown = true;
  }

  Swal.fire({
    title: 'Create Job? (Step 3 of 3)',
    text: `Environment:  ${payload.environment}  Drill Down:  ${payload.drillDown ? 'Yes' : 'No'}`,
    showDenyButton: true,
    showCancelButton: true,
    confirmButtonText: 'Create Job',
    denyButtonText: `Don't Create`,
  }).then(result => {
    if (result.isConfirmed) {
      createCMSSiteTestingJob(payload);
      Swal.fire('Job Request Sent.', '', 'success');
    } else if (result.isDenied) {
      Swal.fire('Job Request Cancelled.', '', 'info');
    }
  });
};

export default createJob;
