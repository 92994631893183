import React from 'react';
import './styles.scss';

const LoadingIndicatorComponent = () => {
  //  Overall position and scaling
  const positionTop: string = '40%';
  const positionLeft: string = '45%';
  const positionWidth: string = '150px';
  const positionHeight: string = '150px';

  //  Rotating bar
  const loadingContainerWidth: string = 'inherit'; //200px
  const loadingContainerHeight: string = 'inherit'; //200px

  //  SVG attributes
  const svgWidth: string = '70%';
  const svgHeight: string = '70%';
  const svgTop: string = '17%';
  const svgLeft: string = '24%';

  return (
    <>
      <div className="loading-indicator-background">
        <div
          style={{ left: positionLeft, top: positionTop, width: positionWidth, height: positionHeight }}
          className="loading-indicator-position-container"
        >
          <div style={{ width: loadingContainerWidth, height: loadingContainerHeight }} className="loading-container">
            <div className="loading-indicator-logo"></div>
            <svg
              style={{ top: svgTop, left: svgLeft, width: svgWidth, height: svgHeight }}
              id="notified-leaf_svg__Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              x={0}
              y={0}
              viewBox="0 0 39.19 41.2"
              xmlSpace="preserve"
            >
              <style>{'.notified-leaf_svg__st2{fill:#00c1de}'}</style>
              <path
                d="M18.16 11.34c.88 2.26.67 4.68-1.27 7.06 2.61 1.64 3.57 3.86 3.47 6.28 6.42 1 15.78-5.28 18.84-9.95-4.4-3.43-15.28-6.39-21.04-3.39z"
                style={{
                  fill: '#93d500',
                }}
              />
              <path
                d="M14.87 20.05c-2.72 1.44-5.13 1.16-7.18-.14-4.07 5.06-3.32 16.31-.8 21.29 5.18-2.08 13.18-10.02 13.46-16.51-2.39-.37-4.38-1.76-5.48-4.64z"
                style={{
                  fill: '#b31983',
                }}
              />
              <path
                className="notified-leaf_svg__st2"
                d="M14.45 17.48c.11-3.07 1.56-5.02 3.71-6.14C15.82 5.28 5.7.32.13 0-.66 5.52 2.22 16.42 7.7 19.91c1.52-1.89 3.72-2.92 6.75-2.43z"
              />
              <path
                d="M18.16 11.34c-2.15 1.12-3.6 3.07-3.71 6.14.86.14 1.7.46 2.44.92 1.94-2.38 2.15-4.8 1.27-7.06z"
                style={{
                  fill: '#58c3be',
                }}
              />
              <path
                d="M14.87 20.05c1.09 2.88 3.08 4.26 5.48 4.64.11-2.43-.86-4.65-3.47-6.28-.54.67-1.24 1.24-2.01 1.64z"
                style={{
                  fill: '#b46b8d',
                }}
              />
              <path className="notified-leaf_svg__st2" d="M16.89 18.4a6.696 6.696 0 0 1 0 0z" />
              <path
                d="M14.87 20.05c.77-.41 1.46-.97 2.01-1.65-.74-.46-1.58-.78-2.44-.92-3.04-.49-5.23.54-6.75 2.43 2.06 1.31 4.46 1.58 7.18.14z"
                style={{
                  fill: '#70aacb',
                }}
              />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoadingIndicatorComponent;
