import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Alert } from 'reactstrap';
import { getApplication, IApplication } from '../../reducers/application';
import { getSession, ISession } from '../../reducers/session';
import { IUser, getUser } from '../../reducers/user';
import { createNavBarHandlers } from '../../utils/NavbarData';
import { APP_ID } from '../../env';

import KeepAliveModal from '../KeepAliveModal';
import AjaxSpinner from '../../components/AjaxSpinner';
import { Location } from 'history';
import { getLocation, RouterRootState } from 'connected-react-router';
import { getRouteData } from '../../utils/helpers';
import { getNavigation } from '../../reducers/navigation';
import { getAuth, IAuthState } from '../../reducers/auth';
import { N3AEntitlements } from '../../constants';
import omit from 'lodash/fp/omit';

export interface ILayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: ILayoutProps) => {
  const dispatch = useDispatch();
  const app: IApplication = useSelector(getApplication, shallowEqual);
  const { pathname } = useSelector<RouterRootState, Location>(getLocation, shallowEqual);
  const { menu } = useSelector(getNavigation, shallowEqual);
  const { tokens }: IAuthState = useSelector(getAuth, shallowEqual);

  const user: IUser = useSelector(getUser, shallowEqual);
  const { entitlements }: ISession = useSelector(getSession, shallowEqual);

  const [isErrorMessage, setErrState] = useState(true);

  const spinner = app.isLoading && <AjaxSpinner />;

  const errorMessage = app.error && !app.isLoading && (
    <Alert color="danger" isOpen={isErrorMessage} toggle={() => setErrState(false)}>
      Oops!, something went wrong.
    </Alert>
  );
  const uniNav = document.getElementById('UniNav') as HTMLElement;

  const UniNavNavigationHandler: any = (evt: Event) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    createNavBarHandlers(dispatch, menu)(evt.detail.id);
  };

  const UniNavLogoutHandler: any = (evt: Event) => {
    const eventType = evt.type === 'logoutHandler' ? 'logout' : 'none';
    createNavBarHandlers(dispatch, menu)(eventType);
  };

  useEffect(() => {
    const menuItem = menu.find(m => (getRouteData(pathname, m.url).isProperPage ? true : false));

    const selectedNavitem = menuItem && menuItem.nav;

    const navElements = menu
      .filter(l => user.entitlements.includes(l.access))
      .map(omit(['access']))
      .map(el => {
        if (el.subNavElements) {
          const subNavElements = el.subNavElements
            .filter(l => user.entitlements.includes(l.access))
            .map(omit(['access']))
            .map(el => {
              return { ...el, id: el.nav, title: el.text, route: el.routes[0] };
            });
          return { ...el, id: el.nav, title: el.text, route: el.routes[0], subNavElements };
        } else {
          return { ...el, id: el.nav, title: el.text, route: el.routes[0] };
        }
      });
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    uniNav.config = {
      applicationId: APP_ID,
      //identityToken: tokens?.idToken,
      customZIndex: 9999,
      navElementsConfig: {
        navElements: navElements,
        selectedNavElementId: selectedNavitem,
        accountNavElement: { id: 'account', title: 'IR Admin Dashboard' },
      },
      user: {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
      },
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    uniNav.addEventListener('navigationHandler', evt => UniNavNavigationHandler(evt));
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    uniNav.addEventListener('logoutHandler', evt => UniNavLogoutHandler(evt));

    return () => {
      uniNav.removeEventListener('navigationHandler', UniNavNavigationHandler);
      uniNav.removeEventListener('logoutHandler', UniNavLogoutHandler);
    };
  }, [uniNav, user, menu]);

  if (tokens && tokens.idToken) {
    return (
      <div>
        <div id="unison-navbar" key={entitlements.length} style={{ position: 'fixed', top: 0, right: 0, left: 0 }} />
        {spinner}
        {errorMessage}
        {children}
        <KeepAliveModal />
      </div>
    );
  }
  return <div />;
};

export const LayoutWrapper = (Page: () => JSX.Element, access = N3AEntitlements.UnisonUser) => {
  return function InnerWrapper() {
    const { entitlements: ents } = useSelector(getUser, shallowEqual) || {};
    const { authenticated }: IAuthState = useSelector(getAuth, shallowEqual);
    const isEntitled = (authenticated && ents && ents.length > 0 && ents.includes(access)) || false;

    return <Layout>{isEntitled ? <Page /> : <div>You are not authorized to access this application</div>}</Layout>;
  };
};
