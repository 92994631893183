import React, { useEffect, useState } from 'react';
import './styles.scss';
import { getCMSSiteTestingJobs, downloadCMSSiteTestingJob } from '../../utils/api';
import createJob from './CreateJob';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
  UncontrolledTooltip,
  PopoverBody,
} from 'reactstrap';
import LoadingIndicatorComponent from './LoadingIndicator/index';
import UpdatingIndicatorComponent from './UpdatingIndicator/index';
interface IJob {
  id: number;
  isDrill: boolean;
  environment: string;
  queuedCount: number;
  processedCount: number;
  createdWhen: string;
}

const CMSSiteTestingJobs = () => {
  const [dropdownButtonOpen, setDropdownButtonOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);

  const dropdownButtonToggle = () => setDropdownButtonOpen(prevState => !prevState);

  const download = (id: string) => {
    downloadCMSSiteTestingJob(id);
  };

  const [jobs, setJobs] = useState<IJob[]>([]);

  const computeComplete = (quedCount: number, processedCount: number) => {
    return ((processedCount / quedCount) * 100).toFixed(2);
  };

  const convertTime = (time: string) => {
    //'EEEE, MMMM d y, hh:mm a'
    var _convertedDate = new Date(time).toDateString();
    var _convertedTime = new Date(time).toLocaleTimeString();
    return `${_convertedDate} ${_convertedTime}`;
  };
  //  Get on init.
  useEffect(() => {
    setLoading(true);
    getCMSSiteTestingJobs().then((data: IJob[]) => {
      setLoading(false);
      setJobs(data);
    });
    const interval = setInterval(() => {
      setUpdating(true);
      getCMSSiteTestingJobs().then((data: IJob[]) => {
        setUpdating(false);
        setJobs(data);
      });
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const getCreateJobWorkFlow = async (env: string) => {
    await createJob(env).then((res: any) => {
      getCMSSiteTestingJobs().then((data: IJob[]) => {
        setJobs(data);
      });
    });
  };

  return (
    <>
      {loading ? (
        <LoadingIndicatorComponent></LoadingIndicatorComponent>
      ) : (
        <div className="cms-site-testing-jobs-container">
          <div className="row">
            <div className="col-12">
              <h3 className="cms-site-testing-jobs-table-heading">
                Jobs
                <Dropdown
                  disabled={loading}
                  className="create-button"
                  isOpen={dropdownButtonOpen}
                  toggle={dropdownButtonToggle}
                  direction="down"
                >
                  <DropdownToggle disabled={loading} color="primary" caret>
                    Create New Job
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      key="ddi-dev"
                      onClick={() => {
                        getCreateJobWorkFlow('DEV');
                      }}
                    >
                      <div>DEV</div>
                    </DropdownItem>
                    <DropdownItem
                      key="ddi-qa"
                      onClick={() => {
                        getCreateJobWorkFlow('QA');
                      }}
                    >
                      <div>QA</div>
                    </DropdownItem>
                    <DropdownItem
                      key="ddi-uat"
                      onClick={() => {
                        getCreateJobWorkFlow('UAT');
                      }}
                    >
                      <div>UAT</div>
                    </DropdownItem>
                    <DropdownItem
                      key="ddi-prod"
                      onClick={() => {
                        getCreateJobWorkFlow('PROD');
                      }}
                    >
                      <div>PROD</div>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </h3>
            </div>
            <div className="col-12">
              <h6>Total Jobs: {jobs.length}</h6>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="cms-site-testing-jobs-table-container">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Date Created</th>
                      <th scope="col">Environment</th>
                      <th scope="col">
                        <div className="progress-heading-text">Progress</div>
                        <div className="progress-heading-indicator">
                          {updating && <UpdatingIndicatorComponent></UpdatingIndicatorComponent>}
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {jobs.map((job, index) => {
                      const completeValue = computeComplete(job.queuedCount, job.processedCount).toString();
                      return (
                        <tr
                          key={'job-' + index}
                          onClick={() => {
                            download(job.id.toString());
                          }}
                        >
                          <td>{job.id}</td>
                          <td>{convertTime(job.createdWhen)}</td>
                          <td>{job.environment}</td>
                          <td id={'job-progress-' + index} key={'job-progress-' + index}>
                            <Progress className="max-progress-width" color="success" value={completeValue}>
                              {completeValue}%
                            </Progress>
                            <UncontrolledTooltip
                              placement="bottom"
                              target={'job-progress-' + index}
                              trigger="hover"
                              key={'progress-tooltip' + index}
                              autohide={true}
                            >
                              <PopoverBody>
                                <div className="subscriptionName" key={'job-queued' + index}>
                                  Processed: {job.processedCount} / {job.queuedCount}
                                </div>
                              </PopoverBody>
                            </UncontrolledTooltip>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CMSSiteTestingJobs;
